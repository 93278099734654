<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ADMIN SECTION PAGES",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "ADMIN SECTION PAGES",
          active: true
        }
      ],

      checkbox:[],
      isCheckAll:false,
      pageData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "pageId",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "slNo", sortable: false, label: "SL NO",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "sectionName", sortable: true, label: "SECTION NAME",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "page", sortable: true, label: "PAGE NAME",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "action", label: "ACTION", sortable: false,  thStyle: { color: "black", "font-size":"16px"}}
      ],
      page: {
        sectionId: "",
        pageDynamic: [{ pageName: "" }],
        pages: [],
      },
      pageEdit:{
        id: "",
        sectionId: "",
        pageName: "",

      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      sectionData: [],
      editData: [],
      successMessage: "",
      errorMessage: "",
      inputs: [
        {
          name: ''
        }
      ],
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.pageData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.pageData.length;

    Master.adminSectionPageList().then((res) => {
      console.log(res)
      this.pageData = res.data.data;
    })
    Master.adminSectionList().then((res) => {
      console.log(res)
      this.sectionData = res.data.data;
    })
  },
  validations: {
    page: {
      sectionId: {required},
      pages:{required},
    },

    pageEdit:{
      sectionId: "",
      pageName: { required  }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */

    editModal(data){
      this.editshowmodal = true
      Master.adminSectionPageDetails({
        id:data
      }).then((res) => {
        this.pageEdit.id = res.data.data.id
        this.pageEdit.sectionId = res.data.data.sectionId
        this.pageEdit.pageName = res.data.data.page
        console.log(res)
      })
    },

    async deleteVendor(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.adminSectionPageDelete(
              {
                pageId: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.adminSectionPageList().then((res) => {
              console.log(res)
              this.pageData = res.data.data;
            })
          })
        }
      });
    },

    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.page.$invalid) {
        return;
      } else {
          Master.adminSectionPageAdd(
              {
                sectionId : this.page.sectionId,
                pages : this.page.pages
              }).then((res) => {
            console.log(res)
            Vue.swal({
              position: "center",
              icon: "success",
              title: ""+res.data.message+"",
              showConfirmButton: false,
              timer: 1500
            });
            // this.successMessage = res.data.message;

            Master.adminSectionPageList().then((res) => {
              console.log(res)
              this.pageData = res.data.data;
            })
            this.showmodal = false;
          }).catch((err) => {
            console.log(err)
            // this.errorMessage = err.response.data.error.name[0];
            this.$bvToast.toast(""+err.response.data.errors+"", {
              title: `Error Message`,
              variant: 'danger',
              solid: true
            });
            this.showmodal = false;
          })

      }
      this.submitted = false;
      this.page.sectionId = "";
      this.page.pages = [];
      this.inputs = [
        {
          name: ''
        }
      ]
    },

    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.pageEdit.$invalid) {
        return;
      } else {
        Master.adminSectionPageUpdate(
            {
              pageId: this.pageEdit.id,
              // sectionId : this.pageEdit.sectionId,
              page : this.pageEdit.pageName
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.adminSectionPageList().then((res) => {
            console.log(res)
            this.pageData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.errors;
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.editshowmodal = false;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.page.sectionId = "";
      this.page.pages = [];
      this.inputs = [
        {
          name: ''
        }
      ],
      this.submitted = false;
      this.showmodal = false;
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addField() {
      this.inputs.push({ name: '' });
    },
    removeField(index) {
      this.inputs.splice(index, 1);
    },

  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="pageData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:cell(slNo)="row">
                    <p class="badge font-size-12"> {{row.index+1}}</p>
                  </template>
                  <template v-slot:cell(sectionName)="row">
                    <p class="badge font-size-12">
                      {{row.item.sectionName}}
                    </p>
                  </template>
                  <template v-slot:cell(page)="row">
                    <p class="badge font-size-12">
                      {{row.item.page}}
                    </p>
                  </template>
                  <template v-slot:cell(checkbox)="row">
                    <div class="custom-control custom-checkbox">
                      <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="custom-control-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.pageId)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteVendor(data.item.pageId)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD PAGE"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">SECTION</label>
          <select class="form-control"
                  id="subMenuId"
                  v-model="page.sectionId"
                  :class="{ 'is-invalid': submitted && $v.page.sectionId.$error }"
                  style="text-transform: uppercase !important;">
            <option value="">SELECT SECTION</option>
            <option v-for="section in sectionData" :value="section.id" :key="section.id">{{section.pageSection}}</option>
          </select>
          <div v-if="submitted && $v.page.sectionId.$error" class="invalid-feedback">
            <span v-if="!$v.page.sectionId.required">SECTION IS REQUIRED</span>
          </div>
        </div>

        <div class="form-group">
          <label class="text-gray-600 font-semibold text-lg">PAGE NAME</label>
          <div
              v-for="(input, index) in inputs"
              :key="index"
              class="input wrapper flex items-center mt-2"
          >
            <input
                type="text"
                v-model="page.pages[index]"
                class="h-10 rounded-lg outline-none p-2"
                :class="{ 'is-invalid': submitted && $v.page.pages.$error }"
                placeholder=" ENTER PAGE NAME"

            />

            <!--          Add Svg Icon-->
            <svg

                @click="addField(index)"
                v-show="index == inputs.length-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                  fill="green"
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>

            <!--          Remove Svg Icon-->
            <svg
                @click="removeField(index)"
                v-show="index || ( !index && inputs.length > 1)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
            <div v-if="submitted && $v.page.pages.$error" class="invalid-feedback">
              <span v-if="!$v.page.pages.required">PAGE NAME IS REQUIRED</span>
            </div>
          </div>

        </div>



        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT PAGE"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
<!--        <div class="form-group">-->
<!--          <label for="name">SECTION</label>-->
<!--          <select class="form-control"-->
<!--                  id="subMenuId"-->
<!--                  v-model="pageEdit.sectionId"-->
<!--                  style="text-transform: uppercase !important;">-->
<!--            <option value="">SELECT SECTION</option>-->
<!--            <option v-for="section in sectionData" :value="section.id" :key="section.id">{{section.name}}</option>-->
<!--          </select>-->
<!--&lt;!&ndash;          <div v-if="submitted && $v.page.sectionId.$error" class="invalid-feedback">&ndash;&gt;-->
<!--&lt;!&ndash;            <span v-if="!$v.page.sectionId.required">SECTION IS REQUIRED</span>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
        <div class="form-group">
          <label for="edit_name">PAGE NAME</label>
          <input
              id="edit_name"
              v-model="pageEdit.pageName"
              type="text"
              class="form-control"
              placeholder="ENTER SECTION NAME"
              style="text-transform: uppercase !important;"
          />
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>